import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "description", "addon", "summary"]

  initialize() {
    this.update()
  }

  update() {
    const selected = this.inputTargets.find((input) => input.checked)
    for (const target of this.descriptionTargets) {
      target.hidden = target.dataset.plan != selected.value
    }

    for (const target of this.addonTargets) {
      target.disabled = target.dataset.term != selected.dataset.term
      target.closest("li").hidden = target.dataset.term != selected.dataset.term
    }

    let selectedAddons = this.addonTargets.filter((input) => input.checked).map((input) => input.value)
    if (selectedAddons.length === 0) {
      selectedAddons = ["none"]
    }

    for (const target of this.summaryTargets) {
      target.hidden = !selectedAddons.includes(target.dataset.addonName)
    }
  }
}
